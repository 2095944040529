/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { isFilled, isString, isUUID } from 'client/redux/validators';
import { normalizeBoolean } from 'client/redux/formHelpers';
import { FormReduxSelect2, FormReduxTextareaJSON, FormReduxInput } from 'client/components/Form/FormRedux';
import * as optionsActions from 'client/redux/fatdi/optionsActions';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import { EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';
import InstanceOptionsFormStateAlert from 'client/components/Instance/components/InstanceOptionsFormStateAlert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'react-bootstrap';
import Icon from 'client/components/Be/Icon';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import Sticky from 'react-stickynode';

const validators = {
  isFilledUUID: [isString(), isFilled(), isUUID()],
}

class ConnectedFATDIOptionsForm extends Component {

  static defaultProps = {
    isSetupMode: false,
    disabled: false,
  }

  static propTypes = {
    disabled: propTypes.bool,
    initialValues: propTypes.object.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    currentFormValues: propTypes.object,
    endpointsByType: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    onNudgeStep: propTypes.func,
  }

  componentDidMount() {
    const { instanceId } = this.props;
    this.props.instanceFatdiOptionsReadMeta(instanceId);
  }

  getInitialValues() {
    const { initialValues } = this.props;
    const translationTable = initialValues.fastSpatisystemTranslationTable || {};
    return {
      ...initialValues,
      fastSpatisystemTranslationTable: JSON.stringify(translationTable, null, 2),
    };
  }

  handleReset = ev => {
    this.props.reset();
  }

  renderRightSettings() {
    const {
      disabled,
      endpointsByType: { topdesk },
      initialValues: {
        incidentProcessingStatusWhenClosedId,
        incidentCallTypesWhenErrand,
        incidentCallTypesWhenCase,
        notOlderThanDays,
        statusMap,
      },
    } = this.props;
    return (
      <div className="well xs-pb-10">
        <InstanceHelpHeader
          subTitle="Options for how TOPdesk Incidents are handled"
          helpFragmentId="instance_fatdi_settings_incidents_help"
        >
          Incident Settings
        </InstanceHelpHeader>
        <Field
          base="fatdiOptionsIncidentProcessingStatusWhenClosedId"
          component={FormReduxSelect2}
          select2Component={EndpointTopdeskRemoteSearcher}
          select2ComponentProps={{forWhat: 'processingStatus', endpointId: topdesk.id}}
          select2Options={{minimumInputLength: 0, minimumResultsForSearch: 0, allowClear: true, placeholder: 'Do not update processing status'}}
          name="incidentProcessingStatusWhenClosedId"
          defaultValue={incidentProcessingStatusWhenClosedId}
          disabled={disabled}
          label="Incident Processing Status for closed Fast API Errands and Cases"
          validate={validators.isUUID}
        />
        <Field
          base="fatdiOptionsIncidentCallTypesWhenCase"
          component={FormReduxSelect2}
          select2Component={EndpointTopdeskRemoteSearcher}
          select2ComponentProps={{forWhat: 'callTypes', endpointId: topdesk.id}}
          select2Options={{minimumInputLength: 0, minimumResultsForSearch: 0}}
          name="incidentCallTypesWhenCase"
          defaultValue={incidentCallTypesWhenCase}
          disabled={disabled}
          required
          label="Call type that results in an Fi2Case"
          validate={validators.isUUID}
        />
        <Field
          base="fatdiOptionsIncidentCallTypesWhenErrand"
          component={FormReduxSelect2}
          select2Component={EndpointTopdeskRemoteSearcher}
          select2ComponentProps={{forWhat: 'callTypes', endpointId: topdesk.id}}
          select2Options={{minimumInputLength: 0, minimumResultsForSearch: 0}}
          name="incidentCallTypesWhenErrand"
          defaultValue={incidentCallTypesWhenErrand}
          disabled={disabled}
          required
          label="Call type that results in an Fi2Errand"
          validate={validators.isUUID}
        />
        <Field
          base="fatdiOptions"
          component={FormReduxInput}
          name="notOlderThanDays"
          value={notOlderThanDays || ''}
          pattern="^\d*$"
          disabled={disabled}
          label="Number of days since last object change"
          helpText="Cases, errands and orders that have not been changed in this amount of days will be ignored"
        />
        <hr />
        <InstanceHelpHeader subTitle="Define how status values are mapped between Fast API and TOPdesk">
          Status Table
        </InstanceHelpHeader>
        <Field
          base="fatdiOptionsStatusMap"
          component={FormReduxTextareaJSON}
          value={statusMap || '[]'}
          getDefaultValue={() => ([])}
          name="statusMap"
          placeholder="Define the status table as valid JSON"
          showAllRows={true}
          helpText="Should be an array of an array of an array of strings. First array with strings is TOPdesk, second array with strings is Fast API equivalent status values."
          disabled={disabled}
        />
      </div>
    );
  }

  renderLeftSettings() {
    const {
      instanceId,
      disabled,
      initialValues: { externalSyncAuthPassword, syncFromFast },
      errorMeta,
      meta,
      isReadingMeta,
    } = this.props;
    return (
      <>
        <div className="well xs-pb-10">
          <InstanceHelpHeader
            subTitle="Settings for a TOPdesk Action notifying an incident has been updated"
          >
            External TOPdesk Incident Callback
          </InstanceHelpHeader>
          <BeRemoteErrorAlert error={errorMeta} />
          <BeLoading loading={isReadingMeta} className="xs-mt-20">
            {meta && (
              <div>
                <div className="form-group">
                  <label className="control-label" htmlFor="fatdiOptionsExternalSyncUserName">
                    HTTP Basic Auth Username
                  </label>
                  <div className="input-group">
                    <input
                      id="fatdiOptionsExternalSyncUserName"
                      className="form-control"
                      type="text"
                      readOnly
                      value={instanceId}
                    />
                    <QuickCopyAddon value={instanceId} />
                  </div>
                </div>
                <Field
                  base="fatdiOptions"
                  component={FormReduxInput}
                  name="externalSyncAuthPassword"
                  value={externalSyncAuthPassword || ''}
                  disabled={disabled}
                  label="HTTP Basic Auth Password"
                />
                <div className="form-group">
                  <label className="control-label" htmlFor="fatdiOptionsExternalSyncUrl">
                    URL to POST to
                  </label>
                  <div className="input-group">
                    <input
                      id="fatdiOptionsExternalSyncUrl"
                      className="form-control"
                      type="text"
                      readOnly
                      value={meta.externalSyncUrl}
                    />
                    <QuickCopyAddon value={meta.externalSyncUrl} />
                  </div>
                </div>
              </div>
            )}
          </BeLoading>
        </div>
        <div className="well xs-pb-10">
          <InstanceHelpHeader>
            Worker settings
          </InstanceHelpHeader>
          <Field
            base="fatdiOptionsSyncFromFast"
            name="syncFromFast"
            component={FormReduxSelect2}
            label="Periodic polling for recently updated objects in FastAPI"
            data={[
              {id: false, text: 'Polling disabled'},
              {id: true, text: 'Polling enabled'},
            ]}
            normalize={normalizeBoolean}
            defaultValue={syncFromFast || ''}
            disabled={disabled}
          />
        </div>
      </>
    );
  }

  render() {
    const {
      dirty,
      disabled,
      invalid,
      isReadingMeta,
      isSetupMode,
      isUpdating,
      onNudgeStep,
      onSubmit,
    } = this.props;
		return (
      <Form id="instanceFatdiForm" onSubmit={this.props.handleSubmit(onSubmit)}>
        <Sticky top={81} bottom="#instanceFatdiForm" innerZ={1000}>
          <InstanceOptionsFormStateAlert
            dirty={dirty}
            disabled={disabled}
            invalid={invalid}
            isSetupMode={isSetupMode}
            onNudgeStep={onNudgeStep}
            onReset={this.handleReset}
            isUpdating={isUpdating}
          />
        </Sticky>
        <div className="xs-mt-20 row">
          <div className="col-md-6">
            {this.renderLeftSettings()}
          </div>
          <div className="col-md-6">
            {this.renderRightSettings()}
          </div>
        </div>
      </Form>
		);
  }
}

function QuickCopyAddon(props) {
  const { value } = props;
  return (
    <CopyToClipboard text={value}>
      <div className="input-group-btn">
        <Button title="Copy to clipboard"><Icon icon="copy" /></Button>
      </div>
    </CopyToClipboard>
  );
}

function mapStateToProps(state) {
  return {
    ...state.fatdi.options,
  };
}

const actions = {
  ...optionsActions,
};

export default connect(mapStateToProps, actions)(reduxForm({
  form: 'fatdiOptions',
  enableReinitialize: true
})(ConnectedFATDIOptionsForm));
