import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

class SplashHeader extends Component {
  render() {
    return (
			<div className={classNames('panel-heading', this.props.className)}>
        <Link to="/login"><img src="/assets/img/logo/246x77.png" alt="logo" width="123" height="39" className="logo-img" /></Link>
        <span className="splash-description">{this.props.children}</span>
      </div>
    );
  }
}

export default SplashHeader;
