import React, { Component } from 'react';
import propTypes from 'prop-types';

class Logo extends Component {

  static propTypes = {
    white: propTypes.bool
  }

  static defaultProps = {
    white: false
  }

  render() {
    const { white } = this.props;
    if(white) return <img src="/assets/img/logo/246x77_White.png" width="123" height="39" alt="LinkNow IO" />;
    return <img src="/assets/img/logo/246x77.png" width="123" height="39" alt="LinkNow IO" />;
  }
}

export default Logo;
